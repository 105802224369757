html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

table {
  width: 100%;

  td:first-of-type {
    font-weight: $font-weight-bold;
  }
}

p {
  iframe {
    margin: auto;
    position: relative;

    width: 50%;
    left: 50%;
    height: 28.125vw;
    transform: translateX(-50%);
  }
}

.progress {
  position: relative;

  &::after {
    position: absolute;
    content: attr(data-value);
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.6rem;
    color: $color-dark;
    background-color: $color-white;
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    text-align: center;
  }
}
