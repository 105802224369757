aside {
  background-color: $color-grey-4;
  padding-left: 2rem;
  margin-top: -2.3rem;
  z-index: 1;

  &::after {
    position: absolute;
    content: '';
    height: calc(100% + 2.3rem);
    width: 100%;
    background: inherit;
    top: -2.3rem;
    left: 100%;
    z-index: inherit;
  }

  nav {
    position: sticky;
    top: 0;
  }
}

.side-navigation {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  max-width: 15rem;
}

.side-navigation-item {
  @include fs-heading(3);

  padding: 1.25rem 0;
  font-weight: bold;
  color: $color-grey-5;

  a {
    transition: color 0.3s ease-out;
  }

  .is-active {
    color: $color-grey-6;
  }

  + .side-navigation-item {
    border-top: 1px solid $color-grey-2;
  }
}
