.has-gradient {
  position: relative;

  &.is-from-black-to-transparent {
    color: $color-white;

    > * {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 0) 70%);
    }
  }

  &.is-140 {
    &::before {
      width: 140%;
    }
  }
}
