.box {
  &.has-medium-padding {
    padding: 2.5rem;
  }

  &.is-dark {
    background-color: $color-dark;
  }

  &.has-horizontal-border {
    border-top: 10px solid currentColor;
    border-bottom: 10px solid currentColor;
  }

  .column {
    &.has-white-background {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: -1rem;
        left: 0;
        width: 100%;
        height: calc(100% + 2rem);
        z-index: 0;
        background-color: $color-white;
      }

      &::after {
        position: absolute;
        content: '';
        top: -1rem;
        left: calc(100% - 1rem);
        height: calc(100% + 2rem);
        width: 100%;
        z-index: 1;
        background-color: $color-white;
      }

      > * {
        position: relative;
        z-index: 1;
      }
    }
  }

  .section &.is-full {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .is-8-tablet &.is-full {
    margin: 0;
  }

  .container &.is-full {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -10px;
      right: 100%;
      height: calc(100% + 20px);
      width: 100%;
      border-top: inherit;
      border-bottom: inherit;
      background-color: inherit;
    }

    &::after {
      content: '';
      position: absolute;
      top: -10px;
      left: 100%;
      height: calc(100% + 20px);
      width: 100%;
      border-top: inherit;
      border-bottom: inherit;
      background-color: inherit;
    }
  }

  @include until($tablet) {
    .media-content {
      width: 100%;
      height: 100%;
      overflow: visible;
    }
  }
}
