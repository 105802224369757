$color-news: #2c4f75;
$color-anime: #dd9a78;
$color-music: #d06a7e;
$color-lifestyle: #bcd57c;
$color-action: #5b4777;

$animepro-colors: (
  'news': $color-news,
  'action': $color-action,
  'anime': $color-anime,
  'music': $color-music,
  'lifestyle': $color-lifestyle
);

$custom-colors: $animepro-colors;

$color-primary: #2c4f75;
$color-footer: #001d3c;

$color-white: #fff;
$color-black: #000;

$color-dark: #4e4e4e;

$color-grey: #f0f0f0;
$color-grey-2: #e8e8e8;
$color-grey-3: #7e7e7e;
$color-grey-4: #f8f8f8;
$color-grey-5: #dbdbdb;
$color-grey-6: #868686;

$color-text: #888;

@each $color, $code in $animepro-colors {
  .is-#{$color} {
    color: $code;
  }
}
