.link {
  display: inline-block;
  font-size: 1.125rem;
  font-weight: $font-weight-bold;
  text-decoration: none;

  &.link-goto {
    color: $color-primary;
    text-transform: uppercase;
  }
}
