.gallery {
  > .colum {
    flex: 1 0 25%;
  }
}

.gallery-item {
  overflow: hidden;

  img {
    transition: transform 0.5s ease-out;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}
