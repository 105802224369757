// Globals
$global-font-family: Roboto, Arial, sans-serif;
$body-color: $color-text;

// Fonts
$font-weight-thin: 100;
$font-weight-regular: 'regular';
$font-weight-bold: 700;

$ds-typography-heading-sizes: (
  1: (min: 30px, max: 60px),
  2: (min: 26px, max: 42px),
  3: (min: 22px, max: 24px),
);

// Pagination
$pagination-current-background-color: $color-primary;
$pagination-current-border-color: $color-primary;
