$top-navigation-colors: (
  $color-news,
  $color-anime,
  $color-music,
  $color-lifestyle,
  $color-action
);

.top-navigation {
  display: flex;
  margin-top: 1rem;
  list-style-type: none;

  @include from($desktop) {
    display: block;
    margin: 1.5rem auto;
  }
}

.top-navigation-item {
  flex-basis: 20%;
  display: inline-block;
  text-align: center;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  color: $color-white;
  text-transform: uppercase;
  font-size: 0.875rem;

  @include from($desktop) {
    width: 8.125rem;
    font-size: 1.25rem;

    @include from($widescreen) {
      width: 10rem;
    }
  }

  &.is-active,
  &:hover {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -0.375rem;
      left: 0;
      height: 0.375rem;
      width: 100%;
      background-color: inherit;
    }
  }

  @for $i from 1 through length($top-navigation-colors) {
    &:nth-child(#{$i}) {
      background-color: nth($top-navigation-colors, $i);
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}
