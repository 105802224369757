.search-field-container {
  color: $color-grey-2;

  .search-field {
    appearance: none;
    padding: 0.25rem;
    font-size: 1.5rem;
    color: $color-black;
    border-bottom: 2px solid $color-grey-2;
  }

  .fa-search {
    color: inherit;
    cursor: pointer;
  }
}
