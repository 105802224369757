html,
body {
  font-family: $global-font-family;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

.heading-1 {
  font-weight: $font-weight-bold;
  line-height: 1.2;
}

.heading-2 {
  font-weight: $font-weight-thin;
  line-height: 1.2;
}

.heading-3 {
  font-weight: $font-weight-bold;
  line-height: 1.4;
}
