.text-flow {
  > * + * {
    margin-top: 1rem;
  }

  &.is-large {
    > * + * {
      margin-top: 2rem;
    }
  }
}
