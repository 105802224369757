.content-list-title {
  position: relative;
  margin-bottom: 2.5rem;
  color: $color-primary;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -0.5rem;
    height: 5px;
    width: 100%;
    background: linear-gradient(90deg, rgba($color-primary, 1) 90%, rgba($color-white, 0) 90%, rgba($color-white, 0) 92%, rgba($color-primary, 1) 92%, rgba($color-primary, 1) 95%, rgba($color-white, 0) 95%, rgba($color-white, 0) 97%, rgba($color-primary, 1) 97%, rgba($color-primary, 1) 100%);
  }

  &.is-uppercase {
    text-transform: uppercase;
  }

  @each $color, $code in $animepro-colors {
    &.is-#{$color} {
      color: $code;

      &::after {
        background: linear-gradient(90deg, rgba($code, 1) 90%, rgba($color-white, 0) 90%, rgba($color-white, 0) 92%, rgba($code, 1) 92%, rgba($code, 1) 95%, rgba($color-white, 0) 95%, rgba($color-white, 0) 97%, rgba($code, 1) 97%, rgba($code, 1) 100%);
      }
    }
  }
}

.content-list-items {
  list-style-type: none;
  margin-bottom: 2rem;
}

.content-list-item {
  position: relative;
  padding-left: 3.25rem;
  color: $color-text;

  &::before {
    content: '';
    position: absolute;
    height: 0.5rem;
    width: 2.5rem;
    left: 0;
    top: 0.75em;
  }

  &.is-undecorated {
    padding-left: 0;

    &::before {
      content: none;
    }
  }

  &.is-news {
    &::before {
      background-color: $color-news;
    }
  }

  &.is-animanga-de,
  &.is-animanga-int {
    &::before {
      background-color: $color-anime;
    }
  }

  &.is-music {
    &::before {
      background-color: $color-music;
    }
  }

  &.is-lifestyle {
    &::before {
      background-color: $color-lifestyle;
    }
  }

  &.is-action {
    &::before {
      background-color: $color-action;
    }
  }

  + .content-list-item {
    margin-top: 1.5rem;
  }
}

.content-list-link {
  text-align: center;

  @include from($tablet) {
    &.is-left {
      text-align: left;
    }

    &.is-right {
      text-align: right;
    }
  }
}

.content-list-image {
  max-width: 220px;

  @include from($desktop) {
    min-width: 220px;
  }
}

.content-list-item-teaser {
  margin: 0.5rem 0;
}
