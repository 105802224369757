.hero {
  &.has-medium-padding {
    padding: 4rem 0;
  }

  &.is-covered {
    background: center center / cover no-repeat;
  }

  &.is-contained {
    background: center right / contain no-repeat;
    background-size: 75%;

    .hero-body {
      padding: 0;
    }
  }
}

.hero-body {
  display: flex;
  align-items: center;
}

.js-flickity {
  .hero {
    width: 100%;
  }
}

.flickity-loaded {
  .hero {
    height: 100%;
  }
}
