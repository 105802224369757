.navigation {
  list-style-type: none;
  display: flex;

  &.is-horizontal {
    flex-direction: row;
  }

  &.is-vertical-mobile {
    flex-direction: column;

    @include from($tablet) {
      flex-direction: row;
    }

    @include until($tablet) {
      > * + * {
        margin-left: 0;
      }
    }
  }

  li {
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  > * + * {
    margin-left: 2rem;

    @at-root.is-narrow & {
      margin-left: 1rem;
    }
  }
}
