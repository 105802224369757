.rating {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  padding-right: 1rem;
  color: $color-text;

  + .rating {
    margin-top: 1rem;
  }

  &.is-total {
    margin: 1.5rem 0 0 -1rem;
    padding: 1rem 1rem 1rem 2.5rem;
    background-color: $color-grey-2;
    text-transform: uppercase;

    .progress {
      background: $color-white;

      &::-webkit-progress-bar {
        background-color: $color-white;
      }
      &::-ms-fill {
        background-color: $color-white;
      }
    }
  }
}

.rating-label {
  flex: 0 0 35%;
  font-weight: bold;
  hyphens: auto;
}

.rating-bar {
  flex: 1 0 auto;
}
