.section {
  &.is-grey {
    background-color: $color-grey;
  }

  &.section-conclusion {
    position: relative;
    color: $color-white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 5;
  }

  .section {
    padding-left: 0;
    padding-right: 0;
  }
}
