$pagination-active-color: $color-primary;

.pagination {
  &.is-filter {
    margin: 0 auto;
    padding-bottom: 1.5rem;

    .pagination-list {
      justify-content: center;
    }
  }
}
