.top-bar {
  @include from($desktop) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .top-bar-right {
    padding: 0.5rem;
    text-align: center;
  }
}
