.image {
  display: block;

  img {
    object-fit: cover;
  }

  &.is-16-9 {
    height: 0;
    padding-top: 9 / 16 * 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.is-bordered {
    position: relative;

    img {
      top: 0.5rem;
      left: 0.5rem;
      width: calc(100% - 1rem);
      height: calc(100% - 1rem);
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border: 0.5rem solid $color-white;
      z-index: 5;
    }
  }

  &.is-shaded {
    position: relative;
    box-shadow: 0 3px 6px 0 rgba($color-black, 0.16);
    margin-bottom: 1.5rem;

    img {
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2rem;
      left: 0;
      bottom: -0.5rem;
      background-color: $color-grey-3;
      border-radius: 50%;
      filter: blur(12px);
      z-index: 0;
    }
  }
}
