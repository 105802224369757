.logo {
  padding-top: 1rem;
  margin: 0 auto;
  max-width: 220px;

  @include from($tablet) {
    margin: 0;
    padding-top: 2rem;
    padding-left: 2rem;
  }
}
