.button {
  display: inline-block;
  font-size: 1.125rem;
  font-weight: $font-weight-bold;
  text-align: center;
  text-decoration: none;
  background-color: $color-white;
  height: auto;

  &.button-goto {
    padding-right: 3.125rem;
    color: $color-primary;
    text-transform: uppercase;
  }

  &.is-shaded {
    box-shadow: 0 3px 6px 0 rgba($color-black, 0.16);
  }

  &.is-rounded {
    border: 2px solid $color-grey-2;
    border-radius: 10px;
  }
}

button {
  &.button {
    display: inline-flex;
  }
}
